<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        :to="{
          path: '/academic_Management/academic_font_management/academic_font_management_home',
        }"
        >学术系统</el-breadcrumb-item
      >
      <el-breadcrumb-item>题库管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 题库信息筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="题型" prop="questionType">
              <el-select
                v-model="queryForm.questionType"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in questionTypeListData"
                  :key="item.flag"
                  :label="item.name"
                  :value="item.flag"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单词" prop="answer">
              <el-input
                v-model="queryForm.answer"
                placeholder="请输入单词"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="含在词库中"
              label-width="130px"
              prop="isindata"
            >
              <el-select v-model="queryForm.isindata" clearable>
                <el-option
                  v-for="item in dict_isindata"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="level">
              <el-select
                v-model="queryForm.level"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_Group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="比赛类型" prop="matchtype">
              <el-select
                v-model="queryForm.matchtype"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_gametype"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
              <el-form-item label="赛程" prop="schedule">
                <el-select
                  v-model="queryForm.schedule"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in raceScheduleList"
                    :key="item.id"
                    :label="item.scheduleName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          <el-col :span="6">
            <el-form-item label="测试题" prop="istest">
              <el-select
                v-model="queryForm.istest"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_istest"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <!-- <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="单词类别" prop="wordCategory">
              <el-select
                v-model="queryForm.wordCategory"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in wordCategoryList"
                  :key="item.flag"
                  :label="item.name"
                  :value="item.flag"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getQuestionListData('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 题库列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="5">
          <span class="title-class title_class">题库列表</span>
        </el-col>
        <el-col :span="19" style="text-align: right">
          <el-button type="primary" @click="addQuestionDataDialog = true"
            >新增题目+</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="questionListData"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column
          label="题型名称"
          prop="questionType"
          :formatter="questionTypeFormatter"
        ></el-table-column>
        <el-table-column label="题目答案" prop="answer"></el-table-column>
        <el-table-column label="题目分数" prop="fullScore"></el-table-column>
        <el-table-column label="赛程" prop="scheduleFlag" :formatter="scheduleFormatter"></el-table-column>
        <el-table-column label="组别" prop="level">
          <template slot-scope="scope">
            <span v-if="scope.row.level === 0">小初组</span>
            <span v-else-if="scope.row.level === 1">小中组</span>
            <span v-else-if="scope.row.level === 2">小高组</span>
            <span v-else-if="scope.row.level === 3">初中组</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column label="是否在词库内" prop="isindata">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isindata == true"
              >词库内</el-tag
            >
            <el-tag type="danger" v-else-if="scope.row.isindata === false"
              >词库外</el-tag
            >
            <el-tag type="warning" v-else>未知</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="单词类别" prop="wordCategory"></el-table-column>
        <el-table-column label="是否为测试题" prop="istest">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.istest == true">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="getQuestionDetailData(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              style="color: #f56c6c"
              @click="deleteDialog(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      :title="isEdit ? '编辑题目' : '新增题目'"
      :visible.sync="addQuestionDataDialog"
      width="55%"
      @close="editQuestionTypeDialogClose"
    >
      <el-form
        :model="addInfoForm"
        label-width="100px"
        ref="addInfoFormRef"
        :rules="addInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="题型" prop="questionType">
              <el-select
                v-model="addInfoForm.questionType"
                placeholder="请选择"
                style="width: 100%"
                @change="questionTypeChange"
                clearable
              >
                <el-option
                  v-for="item in questionTypeListData"
                  :key="item.flag"
                  :label="item.name"
                  :value="item.flag"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="赛程" prop="scheduleFlag">
                <el-select
                  v-model="addInfoForm.scheduleFlag"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in raceScheduleList"
                    :key="item.id"
                    :label="item.scheduleName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
          </el-col>
        </el-row>
        <!-- 挖空题 -->
        <div
          v-if="
            addInfoForm.questionType &&
            (addInfoForm.questionType ===
              'SINGLE_ONLINE_FRIST_BLANK_ONE_SLOT' ||
              addInfoForm.questionType ===
                'SINGLE_ONLINE_FRIST_BLANK_TWO_SLOT' ||
              addInfoForm.questionType ===
                'SINGLE_ONLINE_FRIST_BLANK_THREE_SLOT' ||
                addInfoForm.questionType === 'ROUND2_COMPOSE' || addInfoForm.questionType === 'FORM_BLANK_ONE_SLOT')
          "
        >
          <el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="单词" prop="answer">
                  <el-input
                    v-model="addInfoForm.answer"
                    placeholder="请输入完整单词"
                    clearable
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="
                  addInfoForm.questionType ===
                  'SINGLE_ONLINE_FRIST_BLANK_ONE_SLOT'
                "
              >
                <el-form-item label="挖空位置" prop="slot">
                  <el-input
                    v-model="addInfoForm.slot"
                    placeholder="从0开始计算"
                    clearable
                  >
                  </el-input> </el-form-item
              ></el-col>
              <el-col :span="12" v-else>
                <el-form-item label="挖空位置" prop="slot">
                  <el-input
                    v-model="addInfoForm.slot"
                    placeholder="请输入"
                    clearable
                  >
                  </el-input> </el-form-item
              ></el-col>
            </el-row>
            <div
              style="
                color: red;
                text-align: left;
                width: 95%;
                margin-left: 5%;
                margin-bottom: 20px;
              "
            >
              注意*挖空位置从0开始计算
              例如要挖第二个字母，则输入1，挖多个空用“,”分割，例：2,3
            </div>
            <el-col :span="20">
              <el-form-item label="音频路径" prop="audioUrl">
                <el-input
                  v-model="addInfoForm.audioUrl"
                  placeholder="请输入音频地址"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 看图选择题 -->
        <div
          v-if="addInfoForm.questionType === 'SINGLE_ONLINE_FRIST_SELECT_PIC'"
        >
          <el-row type="flex">
            <el-form-item label="题目图片:" prop="imgUrl">
              <el-upload
                :action="uploadQuestionImageResults.uploadUrl"
                :show-file-list="false"
                :on-success="handleUploadSimpleImgSuccess"
                :before-upload="beforeImgUpload"
                :headers="uploadQuestionImageResults.headerObj"
                :data="{
                  filePath: 'spbcnapp/questionImage/',
                }"
              >
                <el-image
                  v-if="addInfoForm.imgUrl"
                  style="width: 100px; height: 100px"
                  :src="addInfoForm.imgUrl"
                  fit="object-fit"
                >
                </el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="正确单词" prop="answer">
                <el-input
                  v-model="addInfoForm.answer"
                  placeholder="请输入正确单词"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="正确选项" prop="selval">
                <el-input
                  v-model="addInfoForm.selval"
                  placeholder="请输入正确选项（A,B,C,D）"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选项一" prop="answer1">
                <el-input
                  v-model="addInfoForm.answer1"
                  placeholder="请输入单词"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="选项二" prop="answer2">
                <el-input
                  v-model="addInfoForm.answer2"
                  placeholder="请输入单词"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选项三" prop="answer3">
                <el-input
                  v-model="addInfoForm.answer3"
                  placeholder="请输入单词"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="选项四" prop="answer4">
                <el-input
                  v-model="addInfoForm.answer4"
                  placeholder="请输入单词"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
          </el-row>
        </div>
        <!-- 全拼、定义题 -->
        <div
          v-if="
            addInfoForm.questionType ===
              'SINGLE_ONLINE_FRIST_SPELL_GIVEFRIST' ||
            addInfoForm.questionType === 'SINGLE_ONLINE_FRIST_SPELL_ALL' ||
            addInfoForm.questionType === 'ROUND2_ALL_SLOT_ANALOG' ||
            addInfoForm.questionType === 'ROUND2_DEFINITION' ||
            addInfoForm.questionType === 'FORM_ALL_SLOT_ANALOG'
          "
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="单词" prop="answer">
                <el-input
                  v-model="addInfoForm.answer"
                  placeholder="请输入完整单词"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
            <el-col :span="20">
              <el-form-item label="音频路径" prop="audioUrl">
                <el-input
                  v-model="addInfoForm.audioUrl"
                  placeholder="请输入音频地址"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div   v-if="
            addInfoForm.questionType ===
              'FORM_DISORDER'
          ">
            <el-row>
              <el-col :span="12">
              <el-form-item label="单词" prop="answer">
                <el-input
                  v-model="addInfoForm.answer"
                  placeholder="请输入完整单词"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
            <el-col
                :span="12"
              >
                <el-form-item label="显示字母" prop="slot">
                  <el-input
                    v-model="addInfoForm.slot"
                    placeholder="请输入显示字母"
                    clearable
                  >
                  </el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col
                :span="12"
              >
              <el-form-item label="乱序内容" prop="disorder">
                  <el-input
                    v-model="addInfoForm.disorder"
                    placeholder="请输入乱序内容"
                    clearable
                  >
                  </el-input> </el-form-item
              ></el-col>
    </el-row>
          </div>
        <!-- 公共方法 -->
        <div v-if="addInfoForm.questionType">
          <el-row>
            <el-col :span="12">
              <el-form-item label="题目分数" prop="fullScore">
                <el-input
                  v-model="addInfoForm.fullScore"
                  placeholder="请输入分数"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="组别" prop="level">
                <el-select
                  v-model="addInfoForm.level"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in dict_Group"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="isEdit === false">
              <el-form-item
                label="是否同步试卷"
                prop="sync"
                label-width="120px"
              >
                <el-select
                  v-model="addInfoForm.sync"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in dict_sync"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="词库内" prop="isindata">
                <el-select
                  v-model="addInfoForm.isindata"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in dict_isindata"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="单词类别" prop="wordCategory">
                <el-input
                  v-model="addInfoForm.wordCategory"
                  placeholder="请输入单词类别"
                  clearable
                >
                </el-input> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="测试题" prop="istest">
                <el-select
                  v-model="addInfoForm.istest"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in dict_istest"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="addInfoForm.questionType">
        <el-button @click="addQuestionDataDialog = false">取 消</el-button>
        <el-button type="primary" @click="addQuestionData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { questionTypeList, getQuestionList, deleteQuestionData, saveQuestion, getQuestionDetail } from '@/http/api'
export default {
  data () {
    return {
      uploadQuestionImageResults: {
        uploadUrl: this.$http.defaults.baseURL + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      isEdit: false,
      dict_Group: this.$userInfo.dict_group(),
      dict_isindata: this.$userInfo.dict_isindata(),
      dict_gametype: this.$userInfo.dict_gametype(),
      dict_sync: this.$userInfo.dict_sync(),
      dict_questionType: [],
      questionListData: [],
      selectQuestionType: '',
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        level: null
      },
      dict_istest: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: true
        },
        {
          dictCode: 2,
          dictLabel: '否',
          dictValue: false
        }
      ],
      addInfoForm: {
        level: null
      },
      addInfoRules: {
        schedule: [{ required: true, message: '请选择', trigger: 'change' }],
        answer: [{ required: true, message: '请输入该题单词答案', trigger: 'blur' }],
        fullScore: [{ required: true, message: '请输入该题分数', trigger: 'blur' }],
        isindata: [{ required: true, message: '请选择', trigger: 'change' }],
        sync: [{ required: true, message: '请选择', trigger: 'change' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }],
        disorder: [{ required: true, message: '请输入', trigger: 'blur' }],
        istest: [{ required: true, message: '请选择', trigger: 'change' }],
        audioUrl: [{
          required: true,
          message: '请输入该题音频路径',
          trigger: 'blur'
        }],
        slot: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        selval: [{ required: true, message: '请输入', trigger: 'blur' }],
        answer1: [{ required: true, message: '请输入', trigger: 'blur' }],
        answer2: [{ required: true, message: '请输入', trigger: 'blur' }],
        answer3: [{ required: true, message: '请输入', trigger: 'blur' }],
        answer4: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      addQuestionDataDialog: false,
      raceScheduleList: [],
      questionTypeListData: [],
      wordCategoryList: []
    }
  },
  created () {
    this.getQuestionTypeList()
    this.getQuestionListData()
    this.raceScheduleList = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value })
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取题型列表
    getQuestionTypeList () {
      questionTypeList({ status: true }).then((res) => {
        console.log('questionTypeListData', res.data)
        this.questionTypeListData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取题列表
    getQuestionListData (type) {
      if (type === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getQuestionList(this.queryForm).then((res) => {
        this.questionListData = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取题详情
    getQuestionDetailData (rowId) {
      getQuestionDetail(rowId).then((res) => {
        console.log('getQuestionDetail_____', res)
        this.addInfoForm = res.data
        this.addQuestionDataDialog = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getQuestionListData('search')
    },
    questionTypeChange (e) {
      this.selectQuestionType = e
      this.isEdit = false
      this.addInfoForm = {}
      this.$refs.addInfoFormRef.resetFields()
      this.addInfoForm.questionType = this.selectQuestionType
    },
    // 添加题目确定点击事件
    addQuestionData () {
      this.addInfoForm.isindata = Number(this.addInfoForm.isindata)
      this.addInfoForm.fullScore = Number(this.addInfoForm.fullScore)
      this.addInfoForm.sync = Number(this.addInfoForm.sync)
      if (this.addInfoForm.slot) {
        this.addInfoForm.slot = this.addInfoForm.slot.replace(/，/ig, ',')
      }
      saveQuestion(this.addInfoForm).then((res) => {
        this.addQuestionDataDialog = false
        this.$message.success('保存成功')
        this.getQuestionListData()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 选择题目图片成功回调
    handleUploadSimpleImgSuccess (res, file) {
      this.$set(this.addInfoForm, 'imgUrl', res.data)
    },
    // 上传图片前校验
    beforeImgUpload (file) {
      const isJPGPNG = (file.type === 'image/jpeg' || file.type === 'image/png')
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPGPNG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPGPNG && isLt2M
    },
    editQuestionTypeDialogClose () {
      this.isEdit = false
      this.addInfoForm = {}
      this.$refs.addInfoFormRef.resetFields()
    },
    // 删除点击事件
    deleteDialog (rowId) {
      this.$confirm('是否确认删除该题?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteQuestionData(rowId).then((res) => {
          this.$message.success('删除成功')
          this.getQuestionListData()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => { })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getQuestionListData()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getQuestionListData()
    },
    scheduleFormatter (data) {
      var temp = '-'
      for (const key in this.raceScheduleList) {
        if (Object.hasOwnProperty.call(this.raceScheduleList, key)) {
          const element = this.raceScheduleList[key]
          if (element.id === data.scheduleFlag) {
            temp = element.scheduleName
          }
        }
      }
      return temp
    },
    questionTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.questionTypeListData) {
        if (Object.hasOwnProperty.call(this.questionTypeListData, key)) {
          const element = this.questionTypeListData[key]
          if (element.flag === data.questionType) {
            temp = element.name
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.wordImg {
    width: 100px;
    height: 100px;
}
.avatar {
    width: 100px;
    height: 100px;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px solid #8c939d;
}
</style>
